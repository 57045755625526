<script>
import FolderItem from "./FolderItem.vue";
import FileItem from "./FileItem.vue";
import GroupRegisterFolderItem from "./GroupRegisterFolderItem.vue";
import GroupRegisterFileItem from "./GroupRegisterFileItem.vue";
import ReferenceItem from "./ReferenceItem.vue";
import { folderMixins } from "../../../mixins/folder";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache";
import { notificationMixins } from "../../../mixins/notification";
import { trashMixins } from "../../../mixins/trash";
import Pagination from "../../common/Pagination";
import Popup from "../../common/Popup.vue";
import ItemSharing from "../sharing-popup/ItemSharing.vue";
import ResultsFilter from "../../common/ResultsFilter.vue";
import { mapState } from "vuex";
import RetentionSchedulePicker from "../../common/RetentionSchedulePicker.vue";

export default {
  mixins: [
    folderMixins,
    folderPathCacheMixins,
    notificationMixins,
    trashMixins,
  ],
  components: {
    Popup,
    Pagination,
    FolderItem,
    FileItem,
    ItemSharing,
    ResultsFilter,
    ReferenceItem,
    GroupRegisterFolderItem,
    GroupRegisterFileItem,
    RetentionSchedulePicker,
  },
  props: ["folderId", "flag"],
  data() {
    return {
      page: 0,
      pageSize: 25,
      currentSort: "label",
      currentSortDir: "asc",
      isSharing: false,
      isNewFolder: false,
      isNewFolderGroupRegisterAdminOverride: false,
      newFolderName: "",
      filter: "",

      groupRegister: false,
      primaryNumbers: [],
      nextPrimaryNumber: "",
      nextSecondaryNumber: "",
      currentPrimaryNumber: "",
      showSecondaryNumber: false,
      showEmptyTrash: false,

      availableRetentionSchedules: [],
      selectedDefaultRetention: "",
    };
  },
  asyncComputed: {
    folder() {
      return this.getFolder(this.folderId, {
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + "," + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter,
        flag: this.flag,
      });
    },
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
    pages() {
      if (!this.folder) {
        return 0;
      }
      return Math.ceil(this.folder.totalItemCount / this.pageSize);
    },
    sortIcon() {
      if (this.currentSortDir === "asc") {
        return "arrow_upward";
      } else return "arrow_downward";
    },
    isNewGroupRegisterFolderValid() {
      if (
        this.showSecondaryNumber &&
        (!this.currentPrimaryNumber ||
          this.nextSecondaryNumber === this.$t("group-register-calculating"))
      ) {
        return false;
      }
      if (!this.currentPrimaryNumber) {
        return false;
      }
      if (!this.newFolderName) {
        return false;
      }
      return true;
    },
    defaultRetention() {
      if (this.selectedDefaultRetention) {
        return this.selectedDefaultRetention;
      }
      if (this.folder && this.folder.self && this.folder.self.defaultRetention) {
        return this.folder.self.defaultRetention;
      }
      return "";
    }
  },
  methods: {
    refresh() {
      this.$asyncComputed.folder.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    folderSelected(item) {
      this.cacheFolderPath(item);
      this.$router.push({ name: "browse", params: { id: item.id } });
    },
    toUpload() {
      this.$router.push({ name: "upload", params: { id: this.folderId } });
    },
    toDownload() {
      this.$router.push({ name: "download", params: { id: this.folderId } });
    },
    createFolderHandler() {
      this.loadRetentionSchedules();
      this.isNewFolder = true;
      this.showSecondaryNumber = false;
      this.nextSecondaryNumber = null;
      this.newFolderName = "";
    },
    createFolderHandlerGroupRegisterAdminOverride() {
      this.loadRetentionSchedules();
      this.isNewFolderGroupRegisterAdminOverride = true;
      this.newFolderName = "";
    },
    async newFolderConfirmed() {
      this.isNewFolder = false;
      if (this.groupRegister) {
        await this.createGroupRegister(
          { id: this.folderId },
          this.newFolderName,
          this.defaultRetention,
        );
      } else {
        await this.createFolder({ id: this.folderId }, this.newFolderName, this.defaultRetention);
      }
      this.groupRegister = false;
      this.showSuccess(this.$t("operation-successful"));
      this.refresh();
    },
    async newFolderGroupRegisterAdminOverrideConfirmed() {
      this.isNewFolderGroupRegisterAdminOverride = false;
      await this.forceCreateGroupRegisterFolder(
        { id: this.folderId },
        this.newFolderName,
        this.defaultRetention,
      );
      this.groupRegister = false;
      this.showSuccess(this.$t("operation-successful"));
      this.refresh();
    },
    async newGroupRegisterFolderConfirmed() {
      this.isNewFolder = false;
      let name = !this.showSecondaryNumber
        ? this.currentPrimaryNumber
        : this.nextSecondaryNumber;
      if (this.newFolderName) {
        name = `${name} ${this.newFolderName}`.trim();
      }
      await this.createGroupRegisterFolder({ id: this.folderId }, name, this.defaultRetention);
      this.groupRegister = false;
      this.showSuccess(this.$t("operation-successful"));
      this.refresh();
    },
    sharingClosed(changed) {
      this.isSharing = false;
      if (changed) {
        this.refresh();
      }
    },
    sort: function(s) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = s;
    },
    hasRecords() {
      return this.folder && this.folder.items && this.folder.items.length;
    },
    async loadPrimaryNumbers() {
      if (!this.folder.self.groupRegister) {
        return;
      }
      var response = await this.getGroupRegisterPrimaryNumbers(this.folderId, {
        showLoader: true,
      });
      this.primaryNumbers = response.eddNumbers;
    },
    async loadNextPrimaryNumber() {
      if (!this.folder || !this.folder.self.groupRegister) {
        return;
      }
      var response = await this.getNextGroupRegisterPrimaryNumber(
        this.folderId,
        { showLoader: true }
      );
      this.currentPrimaryNumber = response.eddNumber.eddNumberString;
      this.nextPrimaryNumber = response.eddNumber.eddNumberString;
    },
    async loadNextSecondaryNumber() {
      var response = await this.getNextGroupRegisterSecondaryNumber(
        this.folderId,
        this.currentPrimaryNumber,
        { showLoader: true }
      );
      this.nextSecondaryNumber = response.eddNumber.eddNumberString;
    },
    primaryNumberChanged(change) {
      this.currentPrimaryNumber = change.value;
      this.showSecondaryNumber =
        this.currentPrimaryNumber !== this.nextPrimaryNumber;
      this.nextSecondaryNumber = this.$t("group-register-calculating");
      this.loadNextSecondaryNumber();
    },
    handleEmptyTrash() {
      this.showEmptyTrash = true;
    },
    onDefaultRetentionScheduleChangedHandler(defaultRetention) {
      this.selectedDefaultRetention = defaultRetention.id;
    },
    async emptyTrashConfirmed() {
      await this.emptyTrash();
      this.showEmptyTrash = false;
      this.showSuccess(this.$t("empty-trash-successful"));
      this.refresh();
    },
    async loadRetentionSchedules() {
      return this.callAPI("item/retention-schedules", { showLoader: false })
        .then((response) => {
          this.allRetentionSchedules = response.retentionSchedules;
          this.availableRetentionSchedules = this.allRetentionSchedules.filter((r) => r.isActive === true);
        })
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.page = 0;
        this.isSharing = false;
        this.isNewFolder = false;
        this.newFolderName = "";
      }
    },
    folder() {
      this.loadPrimaryNumbers();
      this.loadNextPrimaryNumber();
    },
  },
  created() {
    this.loadNextPrimaryNumber();
  },
};
</script>

<template>
  <div class="browser">
    <portal to="actions">
      <ResultsFilter @filterChange="setFilter" />
      <button
        v-if="
          folder &&
            folder.self.permissions.writeAccess &&
            !folder.self.suspended &&
            !folder.self.trashRepository
        "
        class="primary"
        @click="toUpload"
      >
        {{ $t("upload") }}
      </button>
      <button
        v-if="
          folder &&
            folder.self.permissions.downloadAccess &&
            !folder.self.trashRepository
        "
        class="primary"
        @click="toDownload"
      >
        {{ $t("download") }}
      </button>
      <button
        v-if="
          folder &&
            folder.self.permissions.writeAccess &&
            !folder.self.trashRepository
        "
        @click="isSharing = true"
      >
        {{ $t("share") }}
      </button>
      <ItemSharing
        v-if="isSharing"
        :item="folder.self"
        icon="folder"
        is-folder
        @closed="sharingClosed"
      />
      <button
        v-if="
          folder &&
            folder.self.permissions.writeAccess &&
            !folder.self.suspended &&
            !folder.self.trashRepository
        "
        @click="createFolderHandler"
      >
        <m-icon icon="create_new_folder" />
        {{ $t("new-folder-button") }}
      </button>
      <button
        v-if="
          folder &&
            isAdmin &&
            folder.self.groupRegister &&
            !folder.self.trashRepository
        "
        @click="createFolderHandlerGroupRegisterAdminOverride"
      >
        <m-icon icon="folder_special" />
      </button>
      <button
        class="primary"
        v-if="folder && isAdmin && folder.self.trashRepository"
        @click="handleEmptyTrash"
      >
        <m-icon icon="delete_forever" />
        {{ $t("empty-trash") }}
      </button>
    </portal>

    <Popup
      v-if="isNewFolder && !folder.self.groupRegister"
      :valid="newFolderName.length > 0"
      @cancelled="isNewFolder = false"
      @confirmed="newFolderConfirmed"
    >
      <template v-slot:header>{{ $t("new-folder-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newFolderName" outlined required>
          <m-floating-label>{{
            $t("new-folder-name-placeholder")
          }}</m-floating-label>
        </m-text-field>
        <div class="default-retention">
          <div>
          <label for="retention-schedule">
            {{ $t("new-folder-default-retention") }}
          </label>
          </div>  
          <RetentionSchedulePicker
            id="retention-schedule-picker"
            v-if="availableRetentionSchedules && availableRetentionSchedules.length > 0"
            :item="folder.self"
            :availableRetentionSchedules="availableRetentionSchedules"
            :currentRetentionSchedule="defaultRetention"
            @retention-schedule-changed="
              onDefaultRetentionScheduleChangedHandler
            "
          />
        </div>
        <div v-if="isAdmin" class="group-register">
          <label>
            <input type="checkbox" v-model="groupRegister" />
            {{ $t("group-register-create-folder") }}
          </label>
        </div>
      </template>
    </Popup>

    <Popup
      v-if="isNewFolderGroupRegisterAdminOverride"
      :valid="newFolderName.length > 0"
      @cancelled="isNewFolderGroupRegisterAdminOverride = false"
      @confirmed="newFolderGroupRegisterAdminOverrideConfirmed"
    >
      <template v-slot:header>{{ $t("new-folder-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newFolderName" outlined required>
          <m-floating-label>{{
            $t("new-folder-name-placeholder")
          }}</m-floating-label>
        </m-text-field>
        <div class="default-retention">
          <div>
          <label for="retention-schedule">
            {{ $t("new-folder-default-retention") }}
          </label>
          </div> 
          <RetentionSchedulePicker
            id="retention-schedule-picker-gr-admin"
            v-if="availableRetentionSchedules && availableRetentionSchedules.length > 0"
            :item="folder.self"
            :availableRetentionSchedules="availableRetentionSchedules"
            :currentRetentionSchedule="defaultRetention"
            @retention-schedule-changed="
              onDefaultRetentionScheduleChangedHandler
            "
          />
        </div>
      </template>
    </Popup>

    <Popup
      v-if="showEmptyTrash"
      @cancelled="showEmptyTrash = false"
      @confirmed="emptyTrashConfirmed"
    >
      <template v-slot:header>{{ $t("empty-trash") }}</template>
      <template v-slot:body>
        <!-- <p class="description">{{ $t("empty-trash-description") }}</p> -->
        <p class="description">{{ $t("empty-trash-description2") }}</p>
      </template>
    </Popup>

    <Popup
      v-if="isNewFolder && folder.self.groupRegister"
      :valid="isNewGroupRegisterFolderValid"
      @cancelled="isNewFolder = false"
      @confirmed="newGroupRegisterFolderConfirmed"
    >
      <template v-slot:header>{{
        $t("group-register-new-folder-title")
      }}</template>
      <template v-slot:body>
        <p class="description">{{ $t("group-register-description-1") }}</p>
        <p class="description">{{ $t("group-register-description-2") }}</p>
        <p class="description">{{ $t("group-register-description-3") }}</p>

        <m-select
          id="selectEddNumber"
          outlined
          class="primary-number"
          @change="primaryNumberChanged"
        >
          <option :value="nextPrimaryNumber" selected>{{
            $t("group-register-new-edd-number", { number: nextPrimaryNumber })
          }}</option>
          <option
            v-for="number in primaryNumbers"
            :key="number.eddNumberString"
            :value="number.eddNumberString"
            >{{ number.eddNumberString }}</option
          >
        </m-select>

        <m-text-field
          v-if="showSecondaryNumber"
          :value="nextSecondaryNumber"
          outlined
          class="child-number"
          readonly
        >
          <m-floating-label>{{
            $t("group-register-secondary-edd")
          }}</m-floating-label>
        </m-text-field>
        <p>Add a descriptive folder name:</p>
        <m-text-field v-model="newFolderName" outlined required>
          <m-floating-label>{{
            $t("new-folder-name-placeholder")
          }}</m-floating-label>
        </m-text-field>
        <div class="default-retention">
          <div>
          <label for="retention-schedule">
            {{ $t("new-folder-default-retention") }}
          </label>
          </div>  
          <RetentionSchedulePicker
            id="retention-schedule-picker-gr"
            v-if="availableRetentionSchedules && availableRetentionSchedules.length > 0"
            :item="folder.self"
            :availableRetentionSchedules="availableRetentionSchedules"
            :currentRetentionSchedule="defaultRetention"
            @retention-schedule-changed="
              onDefaultRetentionScheduleChangedHandler
            "
          />
        </div>
      </template>
    </Popup>

    <p v-if="$asyncComputed.folder.updating" class="loading"></p>
    <p v-else-if="$asyncComputed.folder.error" class="load-error">
      Failed to load folder contents
    </p>
    <p v-else-if="!hasRecords()">
      This respository or folder is empty. Try uploading something or creating a
      sub folder.
    </p>
    <table v-else class="listing">
      <thead>
        <tr>
          <th class="icon"></th>
          <th class="name" @click="sort('label')">
            Name
            <span v-if="currentSort == 'label'"
              ><m-icon v-bind:icon="sortIcon" class="sort-icon"
            /></span>
          </th>
          <th
            v-if="folder.self.groupRegister"
            class="owner"
            @click="sort('owner')"
          >
            Creator / Owner
            <span v-if="currentSort == 'owner'"
              ><m-icon v-bind:icon="sortIcon" class="sort-icon"
            /></span>
          </th>
          <th class="created-on" @click="sort('createdOn')">
            Created on
            <span v-if="currentSort == 'createdOn'"
              ><m-icon v-bind:icon="sortIcon" class="sort-icon"
            /></span>
          </th>
          <th class="expires-on" @click="sort('expirationDate')">
            Expires on
            <span v-if="currentSort == 'expirationDate'"
              ><m-icon v-bind:icon="sortIcon" class="sort-icon"
            /></span>
          </th>
          <th class="size" @click="sort('actualSize')">
            Size
            <span v-if="currentSort == 'actualSize'"
              ><m-icon v-bind:icon="sortIcon" class="sort-icon"
            /></span>
          </th>
          <th class="actions">Actions</th>
          <th class="options">Options</th>
        </tr>
      </thead>
      <tbody v-if="folder.self.parentId !== null" class="back">
        <router-link
          tag="tr"
          :to="{ name: 'browse', params: { id: folder.self.parentId } }"
        >
          <td :colspan="folder.self.groupRegister ? 6 : 5">
            <router-link
              :to="{ name: 'browse', params: { id: folder.self.parentId } }"
            >
              <m-icon icon="arrow_back" />
            </router-link>
          </td>
          <td></td>
          <td></td>
        </router-link>
      </tbody>
      <template v-for="item in folder.items">
        <FolderItem
          v-if="item.type === 'folder' && !folder.self.groupRegister"
          :key="item.id"
          :item="item"
          @modified="refresh"
          @selected="folderSelected(item)"
        />
        <GroupRegisterFolderItem
          v-if="item.type === 'folder' && folder.self.groupRegister"
          :key="item.id"
          :item="item"
          @modified="refresh"
          @selected="folderSelected(item)"
        />
        <FileItem
          v-if="item.type === 'file' && !folder.self.groupRegister"
          :key="item.id"
          :item="item"
          @modified="refresh"
        />
        <GroupRegisterFileItem
          v-if="item.type === 'file' && folder.self.groupRegister"
          :key="item.id"
          :item="item"
          @modified="refresh"
        />
        <ReferenceItem
          v-if="item.type == 'reference'"
          :key="item.id"
          :item="item"
          @modified="refresh"
        />
      </template>
    </table>
    <Pagination
      v-if="hasRecords()"
      :page="page"
      :pages="pages"
      :pageSize="pageSize"
      :totalRecords="folder.totalItemCount"
      @pageChange="setPage"
      @pageSizeChange="setPageSize"
    />
  </div>
</template>

<style lang="scss" scoped>
.loading,
.load-error {
  text-align: center;
  margin-top: 4rem;
}
input.filter {
  flex: 1 1 auto;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  color: black;
  padding: 0.5rem 1rem;
}
.sort-icon {
  font-size: 14px;
}
.listing {
  width: 100%;
  font-size: 0.8rem;

  th {
    font-weight: bold;
    color: #5b5b5b;
  }

  th.icon,
  th.actions,
  th.options {
    width: 1px;
  }

  .expires-on {
    width: 6rem;
  }
  .created-on {
    //padding-left: 2rem;
    width: 10rem;
  }
  .size {
    width: 4rem;
  }
  .options {
    text-align: center;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #ebebeb;
      height: 2rem;
    }
  }

  th,
  ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}

.back {
  cursor: pointer;

  &:hover {
    background: #eeeeee;
  }
}

div.group-register {
  margin-top: 1.5rem;
}
div.primary-number,
div.child-number {
  margin-bottom: 1.5rem;
}
p.description {
  margin-bottom: 1.25rem;
}
div.default-retention {
  margin-top: 1.5rem;

  div {
    margin-bottom: 1rem;
    font-weight: bold;
  }
}
</style>
